import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  SET_TIMER,
  DESTROY_MY_INFO,
} from "./mutations-types";
import api from "@/api";
const moment = require("moment");
import Cookies from "js-cookie";

export default {
  /**
   * [인증]
   */

  //로그인
  async signIn({ commit, dispatch }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    const { userAgent, loginId, password } = payload;
    try {
      const res = await api.post("/authenticate", { userAgent, loginId, password });

      const authorities = res.data.authorities;
      var itemIdx = authorities.map(x => x.authorityName).indexOf("ROLE_ADMIN");
      if (itemIdx < 0) {
        Vue.$toast.open({
          message: '관리자 권한이 없습니다.',
          type: 'error',
          duration: 3000,
          dismissible: true
        });
        return;
      }

      const { token } = res.data;
      commit(SET_ACCESS_TOKEN, token);

      // 로그인 ip/시간 저장
      const accessInfo = {
        head_id: res.data.head_id,
        hospital_id: res.data.hospital_id,
        hospital_name: res.data.hospital_name,
        user_id: res.data.user_id,
        userType: res.data.userType,
        loginUserName: res.data.username,
        loginUserId: res.data.loginId,
        ip: res.data.loginIp,
        recentLoginDate: res.data.recentLoginDate
      }
      const CookieName = process.env.VUE_APP_CookieName;
      Cookies.set(CookieName, JSON.stringify(accessInfo), { expires: 100000 });

      // 리로드
      window.location.href = "/dashBoard";
    } catch (err) {
      if (err.response.status === 401) {
        return alert("Please check your ID or password.");
      } else {
        return alert("Login Error");
      }
    }
  },

  //토큰이 있는 경우 내정보 가져오기
  async signinByToken({ commit, dispatch }, token) {
    console.log('savedToken', token);
    
    commit(DESTROY_MY_INFO);
    commit(SET_ACCESS_TOKEN, token);

    try {
      if (token) {
        dispatch("getMeInfo");
      } else {
        this.$router.push({ name: "SignUpPage" });
      }
    } catch (err) {
      if (err.response.status === 500) {
        dispatch("signout");
      }
    }
  },

  async getMeInfo({ commit, dispatch }) {
    try {
      const res = await api.get("/admin/me");
      if (res) {
        return commit(SET_MY_INFO, res.data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        dispatch("signOut");
      } else {
        return alert("me error");
      }
    }
  },

  //로그아웃
  signOut({ commit }) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
  },

  // 타이머
  setTimer({ commit }, payload) {
    commit(SET_TIMER, payload);
  },

  // 회원탈퇴
  async withdrawalUser({ commit }, payload) {
    try {
      const { user_id, loginId, selectedSurvey } = payload;
      const res = await api.post(`/admin/user/withdrawal`, { user_id, loginId, selectedSurvey });
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert("withdrawal error");
    }
  },

  //회원가입
  // async signup({ commit }, payload) {
  //   commit(DESTROY_MY_INFO);
  //   commit(DESTROY_ACCESS_TOKEN);

  //   const { userId, password, name, type, mobile, gender, agree } = payload.userData;

  //   try {
  //     const res = await api.post("/users/signup", { userId, password, name, type, mobile, gender, agree });
  //     if (res) {
  //       alert("회원가입 성공");
  //     }
  //   } catch (err) {
  //     if (err.response.status === 409) {
  //       return alert(err.response.data.message);
  //     } else {
  //       return alert("회원가입 에러");
  //     }
  //   }
  // },
}