import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_TIMER
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";
const CookieName = process.env.VUE_APP_CookieName;
const CookieToken = process.env.VUE_APP_CookieToken;

export default {
  [SET_ACCESS_TOKEN](state, accessToken) {
    if (accessToken) {
      state.accessToken = accessToken;
      api.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;
      Cookies.set(CookieToken, accessToken, { expires: 100000 });
    }
  },
  [DESTROY_ACCESS_TOKEN](state) {
    state.accessToken = "";
    delete api.defaults.headers.common["authorization"];
    Cookies.remove(CookieToken);
    Cookies.remove(CookieName);
  },
  [SET_MY_INFO](state, me) {
    if (me) {
      state.me = me;
    }
  },
  [DESTROY_MY_INFO](state) {
    state.me = null;
  },
  // [SET_BBS](state, bbs) {
  //   if (bbs) {
  //     state.bbs = bbs;
  //   }
  // },
  // [SET_PATIENT](state, value) {
  //   if (value) {
  //     state.patients = value;
  //   }
  // },
  // [SET_STAFF](state, value) {
  //   if (value) {
  //     state.staffs = value;
  //   }
  // },
  // [SET_MEMBERS_LOGS](state, membersLogs) {
  //   if (membersLogs) {
  //     state.membersLogs = membersLogs;
  //   }
  // },
  // [SET_LOGS](state, logs) {
  //   if (logs) {
  //     state.logs = logs;
  //   }
  // },
  // [SET_DECLARATIONS](state, declarations) {
  //   if (declarations) {
  //     state.declarations = declarations;
  //   }
  // },
  // [SET_SURVEY](state, value) {
  //   if (value) {
  //     state.patientSurvey = value;
  //   }
  // },
  // [SET_SURVEY_ANSWERS](state, value) {
  //   if (value) {
  //     state.patientSurveyAnswers = value;
  //   }
  // },
  // [DESTROY_SURVEY_ANSWERS](state) {
  //   state.patientSurveyAnswers = "";
  // },
  // [SET_UNSE](state, unse) {
  //   if (unse) {
  //     state.unse = unse;
  //   }
  // },
  // [SET_TAG](state, tags) {
  //   if (tags) {
  //     state.tags = tags;
  //   }
  // },
  // [SET_BUJUK](state, bujuk) {
  //   if (bujuk) {
  //     state.bujuk = bujuk;
  //   }
  // },
  // [SET_ILLUST](state, illustImage) {
  //   if (illustImage) {
  //     state.illustImage = illustImage;
  //   }
  // },
  // [SET_PRODUCTS](state, products) {
  //   if (products) {
  //     state.products = products;
  //   }
  // },
  [SET_TIMER](state, value) {
    if (value) {
      state.careNoteTimer = value;
    }
  },

};
