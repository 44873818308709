import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

const _root = "_kimCare";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      // 사이드, 탑바 없이 풀 페이지
      component: () => import("@/layouts/full/LayoutFull.vue"),
      children: [
        {
          path: '/',
          redirect: "/login"
        },
        {
          path: "login",
          component: () => import(`@/${_root}/login/LoginPage`),
          beforeEnter: (to, from, next) => {
            // 로그인 상태가 아니면 로그인 페이지로 이동 처리
            if (store.getters.isAuthorized) {
              next("/dashBoard");
              return;
            }
            next();
          }
        },
      ]
    },
    {
      path: "/",
      // 사이드, 텝바가 있는 페이지
      component: () => import(`@/${_root}/Index`),
      children: [
        {
          path: "/",
          component: () => import(`@/${_root}/Pages`),
          children: [
            {
              path: "/dashBoard",
              component: () => import(`@/${_root}/dashboard/DashBoard.vue`),
              meta: { auth: true }
            },
            //** ------ Octavia 확인용 ------- */
            {
              path: "/dashBoard2",
              component: () => import(`@/${_root}/dashboard/DashBoardSample.vue`),
              meta: { auth: true }
            },
            {
              path: "/dashBoard3",
              component: () => import(`@/${_root}/dashboard/Analytic.vue`),
              meta: { auth: true }
            },
            {
              path: "/dashBoard4",
              component: () => import(`@/${_root}/dashboard/JobManagement.vue`),
              meta: { auth: true }
            },
            {
              path: "/simpleTables",
              component: () => import(`@/components/commonV2/table/SimpleTables.vue`),
              meta: { auth: true }
            },
            {
              path: "/dataTables",
              component: () => import(`@/components/commonV2/table/DataTables.vue`),
              meta: { auth: true }
            },
            {
              path: "/patientPhoto",
              component: () => import(`@/${_root}/patient/ListColumnOne.vue`),
              meta: { auth: true }
            },
            /** ------------------ */
            {
              path: "/consulting",
              component: () => import(`@/${_root}/consulting/Consulting.vue`),
              meta: { auth: true }
            },
            {
              path: "/userBooking",
              component: () => import(`@/${_root}/userBooking/UserBooking.vue`),
              meta: { auth: true }
            },
            {
              path: "/patient",
              component: () => import(`@/${_root}/patient/Patient.vue`),
              meta: { auth: true }
            },
            {
              path: "/staff",
              component: () => import(`@/${_root}/staff/Staff.vue`),
              meta: { auth: true }
            },
            {
              path: "/clinic",
              component: () => import(`@/${_root}/clinic/Clinic.vue`),
              meta: { auth: true }
            },
            {
              path: "/setting",
              component: () => import(`@/${_root}/setting/Setting.vue`),
              meta: { auth: true }
            },
            {
              path: "/billing",
              component: () => import(`@/${_root}/billing/BillingDashBoard.vue`),
              meta: { auth: true }
            },
            {
              path: "/billing2",
              component: () => import(`@/${_root}/dashboard/DashBoardSample.vue`),
              meta: { auth: true }
            },
            {
              path: "/statistics",
              component: () => import(`@/${_root}/dashboard/Analytic.vue`),
              meta: { auth: true }
            },
            {
              path: "/qna",
              component: () => import(`@/components/commonV2/chat/Chat.vue`),
              meta: { auth: true }
            },
            {
              path: "/contents",
              component: () => import(`@/components/commonV2/content/Contents.vue`),
              meta: { auth: true }
            },
            {
              path: "/addHeadOffice",
              component: () => import(`@/components/commonV2/headOffice/HeadOffice.vue`),
              meta: { auth: true }
            },
            {
              path: "/test",
              component: () => import(`@/${_root}/test/Test.vue`),
              meta: { auth: true }
            },
          ]
        }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth && !store.getters.isAuthorized) {
    console.log('인증이 필요합니다');
    next("/login");
    return;
  }
  next();
});

export default router;
