export default {
  isAuthorized(state) {
    return state.accessToken.length > 0; // && !!state.me;
  },
  meInfo(state) {
    return state.me;
  },
  isCareTimerClose(state) {
    return state.mPatient.careTimerClose;
  },
  isSurveySetOpen(state) {
    return state.mPatient.surveySetOpen;
  }
};

