export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_ACCESS_USER_TYPE = "SET_ACCESS_USER_TYPE";
export const SET_MY_INFO = "SET_MY_INFO";
export const DESTROY_ACCESS_TOKEN = "DESTROY_ACCESS_TOKEN";
export const DESTROY_MY_INFO = "DESTROY_MY_INFO";
// export const SET_BBS = "SET_BBS";
// export const SET_PATIENT = "SET_PATIENT";
// export const SET_STAFF = "SET_STAFF";
// export const SET_MEMBERS_LOGS = "SET_MEMBERS_LOGS";
// export const SET_LOGS = "SET_LOGS";
// export const SET_DECLARATIONS = "SET_DECLARATIONS";
// export const SET_SURVEY = "SET_SURVEY";
// export const SET_SURVEY_ANSWERS = "SET_SURVEY_ANSWERS";
// export const DESTROY_SURVEY_ANSWERS = "DESTROY_SURVEY_ANSWERS";
// export const SET_UNSE = "SET_UNSE";
// export const SET_TAG = "SET_TAG";
// export const SET_BUJUK = "SET_BUJUK";
// export const SET_ILLUST = "SET_ILLUST";
// export const SET_PRODUCTS = "SET_PRODUCTS";

export const SET_TEST = "SET_TEST";
export const SET_ICD_CODE = "SET_ICD_CODE";
export const SET_PHYSICIAN = "SET_PHYSICIAN";
export const SET_CARE_NOTE_TIME = "SET_CARE_NOTE_TIME";
export const SET_CARE_NOTE_TIMER_CLOSE = "SET_CARE_NOTE_TIMER_CLOSE";
export const SET_SURVEY_SET_OPEN = "SET_SURVEY_SET_OPEN";
export const SET_TIMER = "SET_TIMER";
export const SET_USER_SURVEY = "SET_USER_SURVEY";
export const DESTROY_USER_SURVEY = "DESTROY_USER_SURVEY";
export const SET_SINGLE_CHAT_INFO = "SET_SINGLE_CHAT_INFO";


