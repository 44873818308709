import {
  SET_CARE_NOTE_TIMER_CLOSE,
  SET_SURVEY_SET_OPEN,
  SET_CARE_NOTE_TIME,
  SET_USER_SURVEY,
  DESTROY_USER_SURVEY
} from "../mutations-types";
import api from "@/api";

const actions = {
  setCareNoteTime({ commit }, timer) {
    commit(SET_CARE_NOTE_TIME, timer);
  },
  setCareNoteTimerClose({ commit }, state) {
    commit(SET_CARE_NOTE_TIMER_CLOSE, state);
    console.log('afterClose');
  },
  setUserSurvey({ commit }, userSurvey) {
    commit(SET_USER_SURVEY, userSurvey);
  },
  clearUserSurvey({ commit }) {
    commit(DESTROY_USER_SURVEY);
  },
  setSurveySetOpen({ commit }, state) {
    commit(SET_SURVEY_SET_OPEN, state);
  },
}

const mutations = {
  [SET_CARE_NOTE_TIME](state, value) {
    if (value) {
      state.careTime = value;
    }
  },
  [SET_CARE_NOTE_TIMER_CLOSE](state, value) {
    // if (value) {
    state.careTimerClose = value;
    // }
  },
  [SET_SURVEY_SET_OPEN](state, value) {
    state.surveySetOpen = value;
  },
  [SET_USER_SURVEY](state, value) {
    if (value) {
      state.userSurvey = value;
    }
  },
  [DESTROY_USER_SURVEY](state) {
    state.userSurvey = null;
  },
}
const state = {
  careTime: '',
  careTimerClose: true,
  surveySetOpen: false,
  userSurvey: null
}
const getters = {}
export default {
  state,
  getters,
  actions,
  mutations,
}
