<template>
  <v-list-item
    v-ripple="{ class: 'primary--text' }"
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="` ${isDark ? ' grey--text text--lighten-5' : 'primary--text'}`"
    :height="48"
    class="text-18 pl-6"
    :class="{
      'primary--text v-list-item--active v-list-item v-list-item--link theme--light v-list-item--active':
        forceActive && !isDark,
      'grey--text text--lighten-5 v-list-item--active v-list-item v-list-item--link theme--light v-list-item--active':
        forceActive && isDark,
    }"
  >
    <v-list-item-icon v-if="text" class="v-list-item__icon--text" v-text="computedText" />
    <v-list-item-icon v-else-if="item.icon">
      <v-badge dot v-if="item.icon === 'mdi-forum' && alertDot">
        <v-icon v-text="item.icon" />
      </v-badge>
      <v-icon v-else v-text="item.icon"></v-icon>
    </v-list-item-icon>

    <v-list-item-content v-if="item.title || item.subtitle" class="pa-0 text-14">
      <v-list-item-title class="ma-0 text-14" v-text="item.title" />

      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
    alertDot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { forceActive: false };
  },
  computed: {
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
  mounted() {
    let movingPathname = this.$route.path.split("/");
    const urlEnd = movingPathname[movingPathname.length - 1].toLowerCase();
    if (urlEnd.includes("clinicstaff") && this.item.group === "clinic") {
      //클리닉, 클리닉스태프 명칭 겹치므로 이부분만 예외처리
      this.forceActive = false;
    } else {
      this.forceActive = !!urlEnd.includes(this.item.group.toLowerCase());
    }
  },
  watch: {
    $route(to) {
      let movingPathname = to.path.split("/");
      const urlEnd = movingPathname[movingPathname.length - 1].toLowerCase();
      if (urlEnd.includes("clinicstaff") && this.item.group === "clinic") {
        //클리닉, 클리닉스태프 명칭 겹치므로 이부분만 예외처리
        this.forceActive = false;
      } else {
        this.forceActive = !!urlEnd.includes(this.item.group.toLowerCase());
      }

      // qna 선택시 alertDot false
      if (to.path === "/qna") this.$emit("moveToQnAEmit");
    },
  },
};
</script>
