
import { makeExpireDay } from '@/utils/helper';

/** 쿠키명 : PRMS_userData / PRMS_ThemeData 처리 (기본 1일) 
 * 쿠키 생성 한글 문제(사파리) -> en/decode 처리 필요~
*/
const saveUserDataCookie = (value) => {  
  value = encodeURI(value)
  document.cookie = `PRMS_userData=${value}${makeExpireDay(1 * 1)}`;
}

const saveThemeDataCookie = (value) => {
  document.cookie = `PRMS_ThemeData=${value}`;
}

const getUserDataFromCookie = () => {
  let value = document.cookie.replace(
    /(?:(?:^|.*;\s*)PRMS_userData\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  )
  value = decodeURI(value)
  return value;
}
const getThemeDataFromCookie = () => {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)PRMS_ThemeData\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
}

function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
}

export {
  saveUserDataCookie,
  saveThemeDataCookie,
  getUserDataFromCookie,
  getThemeDataFromCookie,
  deleteCookie,
};
