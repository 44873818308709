<template>
  <v-app>
    <!-- <snackbar />
    <confirm /> -->
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      downKey: 0,
      mainTitle: "Kim Service Portal",
    };
  },
  metaInfo() {
    return {
      title: this.mainTitle,
    };
  },
  created() {
    // 최근 build / build 날짜 확인
    // const code = process.env.VUE_APP_VERSION_CODE;
    // const release_date = process.env.VUE_APP_RELEASE_DATE;
    // const isDev = process.env.NODE_ENV;
    // console.log("code / release_date", code, release_date, isDev);

    this.initLocale();
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      console.log(mq);
      this.$vuetify.theme.dark = e.matches;
    });
    // this.getRouterParams();
  },
  methods: {
    initLocale() {
      let locale = "ko";
      // let locale = "en";
      // const envURL = process.env.VUE_APP_API_URL;
      // console.log("envURL", envURL);
      // if (envURL.indexOf("kr") > -1) locale = "ko"; // (한국서비스)국내 사내병원용 처리시 선택

      // // 한국서비스 준비
      // if (locale === "ko") this.mainTitle = "한국_" + this.mainTitle;

      this.$i18n.locale = locale;
      this.$moment().locale(locale);
      console.log("locale", locale);
    },
    getRouterParams() {
      // console.log(this.$router.currentRoute.query);
    },
    windowCloseHandler() {
      // console.log("windowClose", this.downKey);
    },
  },
  // key test
  mounted() {
    document.onkeydown = function (e) {
      // console.log("key", e.keyCode);
      this.downKey = e.keyCode;
      /* F5, Ctrl+r, Ctrl+F5 */
      if (e.keyCode == 116 || (e.ctrlKey == true && e.keyCode == 82)) {
        e.cancelBubble = true;
        e.returnValue = false;
        // setTimeout(function () {
        //   // window.location.reload();
        // }, 1000);
        return false;
      }
    };
    // $(document).on("keydown", function (e) {
    //   console.log("key", e.keyCode);
    // });

    // window 닫을때 처리
    window.addEventListener("beforeunload", this.windowCloseHandler, false);
    // window.addEventListener("onunload", this.windowCloseHandler);

    // window.addEventListener(
    //   "beforeunload",
    //   function (event) {
    //     // console.log(performance.navigation.type, "performance.navigation.type");

    //     alert("windowClose?");

    //     // event.preventDefault();

    //     // event.returnValue = "";

    // document.onkeydown = function (e) {
    //   console.log("key", e.keyCode);
    //   /* F5, Ctrl+r, Ctrl+F5 */
    //   // if (e.keyCode == 116 || (e.ctrlKey == true && e.keyCode == 82)) {
    //   //   e.cancelBubble = true;
    //   //   e.returnValue = false;
    //   //   setTimeout(function () {
    //   //     // window.location.reload();
    //   //   }, 1000);
    //   //   return false;
    //   // }
    // };
    //     $(document).on("keydown", function (e) {
    //       console.log("key", e.keyCode);
    //     });
    //   },
    //   false
    // );
  },
};
</script>
