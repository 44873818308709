import Vuex from "vuex";
import Vue from "vue";
import themeConfig from "./modules/themeConfig";

import state from "./states.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

import mCommon from "./modules/CommonInfo.js";
import mPatient from "./modules/patient.js";
import chat from "./modules/chat.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mCommon,
    mPatient,
    themeConfig,
    chat
  },
  state,
  getters,
  mutations,
  actions,
});
