import axios from "axios";

console.log(process.env.NODE_ENV);
export default process.env.NODE_ENV == "production"
    ? axios.create({
        //production 환경 (AWS EC2)
        baseURL: "https://was.kimcare.site", //
    })
    : axios.create({
        //development 환경
        baseURL: "http://localhost:9502",
        // baseURL: "http://52.78.201.88:9502", // aws-독립
        // baseURL: "https://was.kimcare.site", //
    });