import {
  SET_SINGLE_CHAT_INFO
} from "../mutations-types";

const actions = {
  setSingleChatInfo({ commit }, timer) {
    commit(SET_SINGLE_CHAT_INFO, timer);
  },
}

const mutations = {
  [SET_SINGLE_CHAT_INFO](state, value) {
    if (value) {
      state.singleChatInfo = value;
    }
  },
}
const state = {
  singleChatInfo: null
}
const getters = {
  singleChatInfo(state) {
    return state.singleChatInfo;
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
}