import {
} from "../mutations-types";
import axios from "axios";
import api from "@/api";
import Cookies from "js-cookie";
const CookieName = process.env.VUE_APP_CookieName;
let accessInfo;
let head_id = 0;
let user_id = 0;
let userType = '';
if (Cookies.get(CookieName) !== undefined) {
  accessInfo = JSON.parse(Cookies.get(CookieName));
  head_id = accessInfo.head_id;
  user_id = accessInfo.user_id;
  userType = accessInfo.userType;
}

const actions = {
  // **** 공통사용모듈 **** //
  async getApiDataQuery({ commit }, endpoint) {
    try {
      const res = await axios.get(`${endpoint}`);
      if (res) {
        return res;
      }
    } catch (err) {
      return alert(`${endpoint} error`);
    }
  },

  async getDataQuery({ commit }, payload) {
    // console.log('payload', payload);
    payload.updUpk = user_id; // 관리자 아이디
    payload.head_id = head_id;
    if (payload.headOffice_id > 0)
      payload.head_id = payload.headOffice_id;
    try {
      const res = await api.post(`/admin/${payload.endpoint}`, payload);
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert(`${payload.endpoint} error`);
    }
  },

  async saveDataQuery({ commit }, payload) {
    console.log('payload', payload);
    payload.updUpk = user_id; // 관리자 아이디
    payload.head_id = head_id;
    if (payload.headOffice_id > 0)
      payload.head_id = payload.headOffice_id;
    try {
      const res = await api.put(`/admin/${payload.endpoint}`, payload);
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert(`${payload.endpoint} error`);
    }
  },

  // 로그인 전 처리(find pw)
  async saveDataPreLoginQuery({ commit }, payload) {
    try {
      const res = await api.put(`/${payload.endpoint}`, payload);
      if (res) {
        return res.data;
      }
    } catch (err) {
      return alert(`${payload.endpoint} error`);
    }
  },
}

const mutations = {
  // [SET_TEST](state, value) {
  //   if (value) {
  //     state.test = value;
  //   }
  // },
}
const state = {
  // test: '',
}
const getters = {}
export default {
  state,
  getters,
  actions,
  mutations,
}
