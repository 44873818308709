import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueCookies from "vue-cookies";
import VueMoment from "vue-moment";
import { Base64 } from "js-base64";
import vuetify from "./plugins/vuetify";
import octaviaKit from "@/plugins/octavia.kit";
import i18n from "@/lang/lang";
import VueApexCharts from "vue-apexcharts";
import Cookies from "js-cookie";
import Vue2Editor from "vue2-editor";

// mock
import "./fake-db/index.js";
import * as helpers from "@/utils/helper";
import "./plugins";

// import * as locale from "@/utils/locale";
// import * as cValue from "@/utils/changeValue";
// import * as authRWChk from "@/utils/authRWCheck";

// import Snackbar from "@/components/common/Snackbar";
// import Confirm from "@/components/common/Dialog/Dialog_CommonConfirm";


// const hostname = document.location.hostname;
// const envName = process.env.VUE_APP_API_URL;
// console.log('main.js', hostname, envName);

// Vue.component("snackbar", Snackbar);
Vue.prototype.$helper = helpers;
// Vue.prototype.$snackbar = Snackbar;
// Vue.prototype.$locale = locale;
// Vue.prototype.$cValue = cValue;
// Vue.prototype.$authRWChk = authRWChk;
// Vue.prototype.$Base64 = Base64;
// Vue.prototype.$eventBus = new Vue();

// Vue.prototype.$socket = socket;

Vue.component("apexchart", VueApexCharts);
// Vue.component("snackbar", Snackbar);
// Vue.component("confirm", Confirm);
// Vue.prototype.$snackbar = Snackbar;
// Vue.prototype.$confirm = Confirm;

Vue.use(VueToast);
Vue.use(octaviaKit);
Vue.use(VueCookies);
Vue.use(VueMoment);
Vue.use(Vue2Editor);

// Vue.$cookies.config("1d");
// Vue.config.productionTip = false;

const CookieToken = process.env.VUE_APP_CookieToken;
const savedToken = Cookies.get(CookieToken);
if (savedToken) {
  store.dispatch("signinByToken", savedToken).then(() => { });
}

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");