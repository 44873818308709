import XLSX from 'xlsx';
import Vue from 'vue';
const moment = require("moment");
const modelService = require('@/utils/dataModel');

// const mmddyy = 'MM/DD/YYYY';
// const mmddyyAmPmTime = 'MM/DD/YYYY a h:mm';
// const mmddyyTime = 'MM/DD/YYYY HH:mm:ss';

// const mmddyyDash = 'YYYY-MM-DD';
// const mmddyyAmPmTimeDash = 'YYYY-MM-DD a h:mm';
// const mmddyyTimeDash = 'YYYY-MM-DD HH:mm:ss';

/** nl2br 처리 */
export const js_nl2br = str => {
  if (str === "" || str == null) {
    return "";
  }
  return str.replace(/(\r\n|\n\r|\r|\n)/g, "<br />");
};

export const showTostMessage = (position, message, type) => {
  if (message === '') return;
  if (type === '') return;
  let _position = position === '' ? 'bottom-right' : position;
  Vue.$toast.open({
    position: _position,
    message: message,
    type: type,
    duration: 1000,
    dismissible: true,
  });
}

// 설문내용 처리(단수처리)
export const makeAnswerArray = (num, data) => {
  if (data === null || data === undefined) return [];
  console.log('', data);
  const arrayList = [];
  let apk = 0;
  let aData = '';
  let isAnswer = false;
  for (let i = 0; i < num; i++) {
    if (data.ipk > 0) {
      apk = data.answers[i] === undefined ? 0 : data.answers[i].id;
      aData = data.answers[i] === undefined ? '' : data.answers[i].answer;
      const itemIdx = data.answerResults.map((x) => x.patientSurveyAnswer_id).indexOf(apk);
      isAnswer = itemIdx > -1 ? true : false;
    }
    const d = {
      id: i,
      value: aData,
      apk: apk,
      isAnswer: isAnswer,
    };
    console.log('', data.ipk, d, data);

    arrayList.push(d);
  }
  return arrayList;
}

export const requiredCheck = (itemId, itemRef, itemMsg, focusOption) => {
  if (itemId === 0 || itemId === '' || itemId === null || itemId === undefined) {
    Vue.$toast.open({
      message: `${itemMsg}`,
      type: 'error',
      duration: 1000,
      dismissible: true,
    });

    // combo일경우 제외
    if (focusOption === undefined) {
      itemRef.focus();
    }
    return false;
  }
  return true;
}

export const validCheck = (valid, itemRef, itemMsg, focusOption) => {
  // console.log('', valid, itemRef, itemMsg, focusOption);
  if (valid === true) return true;
  Vue.$toast.open({
    message: `${itemMsg}`,
    type: 'error',
    duration: 1000,
    dismissible: true,
  });

  // combo일경우 제외
  if (focusOption === undefined) {
    if (itemRef !== null) {
      itemRef.focus();
    }
  }
  return false;
}

// 회원등급 정의
export const memberGrade = totalPoint => {
  let memberGrade = '';
  let memberGradeHan = '';
  if (totalPoint < 100) {
    memberGrade = "bronze";
    memberGradeHan = "브론즈";
  } else if (totalPoint >= 100 && totalPoint <= 300) {
    memberGrade = "silver";
    memberGradeHan = "실버";
  } else if (totalPoint > 300) {
    memberGrade = "gold";
    memberGradeHan = "골드";
  }
  return {
    memberGrade: memberGrade,
    memberGradeHan: memberGradeHan,
  }
};

export const makeExpireDay = days => {
  const date = new Date();
  // const days = 365;
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = '; expires=' + date.toGMTString() + '; path=/';
  // console.log('', expires);
  return expires;
};

/** 다국어처리에 활용 */
export const makeStringToArray = (string, splitType) => {
  if (string === null || string === '') return []
  return string.split(splitType);
}

/** 다국어중 표시할 언어 선택 */
export const definedStringI18 = (stringArray, pos) => {
  if (stringArray === null) return "";
  let retString = "";
  switch (pos) {
    case 'kr':
      retString = stringArray.length > -1 ? stringArray[0] : "";
      break;
    case 'en':
      retString = stringArray.length > 0 ? stringArray[1] : "";
      break;
    case 'ch':
      retString = stringArray.length > 1 ? stringArray[2] : "";
      break;
  }
  return retString;
}

/** 국적별 수수료 변동 */
export const defineChargeWidthNation = (Object) => {
  let reCharge = {
    surgery: 0,
    procedure: 0
  };
  if (Object === null) return reCharge;
  // let tD = Object[0];
  let tD = Object;
  // console.log('tD.national', tD);
  switch (tD.national) {
    case 'cn':
      reCharge.surgery = tD.cnSurgery;
      reCharge.procedure = tD.cnProcedure;
      break;
    case 'bt':
      reCharge.surgery = tD.btSurgery;
      reCharge.procedure = tD.btProcedure;
      break;
    default:
      reCharge.surgery = tD.basicSurgery;
      reCharge.procedure = tD.basicProcedure;
      break;
  }
  return reCharge;
}

export const calDueMonth = (startDate, endDate) => {
  console.log('calDueMonth : ', startDate, endDate);
  if (startDate === null || endDate === null) return 'month3';
  if (startDate === '' || endDate === '') return 'month3';

  const stringStart = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
  const stringEnd = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
  const startTime = moment(stringStart);
  const endTime = moment(stringEnd);
  const diffDate = moment.duration(endTime.diff(startTime)).asDays();
  // console.log('diffDate', diffDate);
  let returnVal = 1;
  if (diffDate > 30) returnVal = 3;
  if (diffDate > 90) returnVal = 6;
  return 'month' + returnVal;
}

export const makeContact = (contacts) => {
  console.log('contacts', contacts.length);
  if (contacts === undefined || contacts.length === 0) {
    return {
      country: '',
      cell: '',
      phone: '',
      address: modelService.defaultAddressModel(),
    };
  }
  let targetContact = contacts.sort((b, a) => new Date(a.regDate) - new Date(b.regDate));
  return targetContact[0];
}

export const makeBiometrics = (indicatorType, bioInfos) => {
  if (bioInfos.length === 0) return [{ value: '', unit: '' }];
  let targetIndicator = [];
  bioInfos.forEach((element) => {
    if (element.indicator === indicatorType) {
      targetIndicator.push(element);
    }
  });
  // updDate로 순서변경(최근 1개)
  const targetIndicatorUpdDate = targetIndicator.sort((b, a) => new Date(a.updDate) - new Date(b.updDate));
  return targetIndicatorUpdDate[0];
}

export const dataValidate = (data) => {
  if (data === null || data === undefined) return '';
  return data;
}

// 날짜빈값 null 처리 -> 없으면 오늘날짜
export const dateValidate = date => {
  return date === null || date === '' ? moment() : moment(date);
}

export const changeStringToArr = (data) => {
  const returnDate = data === '' || data === null ? [] : data.split(",")
  return returnDate;
}

export const changeNameFullDp = (fname, mname, lname) => {
  const fName = fname === null || fname === '' ? '' : fname;
  const mName = mname === null || mname === '' ? '' : mname;
  const lName = lname === null || lname === '' ? '' : lname;
  return fName + " " + mName + " " + lName;
}

export const changeDateToForUpdateDp = (regDate, updDate) => {
  const returnDate = updDate === '' || updDate === null
    ? moment(regDate).format('MM/DD/YYYY a hh:mm')
    : moment(updDate).format('MM/DD/YYYY a hh:mm')
  return returnDate;
}

// Date -> String 변환(시간 00:00)
export const changeDateToStringTimeDp = (date) => {
  const format = "a hh:mm";
  return date === null || date === '' ? '' : moment(date).format(format);
}

export const checkIsYNPhoto = (photoImage) => {
  return photoImage === '' || photoImage === null ? 'N' : 'Y';
}

// Date -> String 변환
export const changeDateToStringForDp = (date, includeTime) => {
  const format = includeTime ? 'MM/DD/YYYY a h:mm' : 'MM/DD/YYYY';
  return date === null || date === '' ? '' : moment(date).format(format);
}

// Date -> String 변환(시간 00:00)
export const changeDateToStringForDp2 = (date, includeTime) => {
  const format = includeTime ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY';
  return date === null || date === '' ? '' : moment(date).format(format);
}

// String -> date
export const changeStringToDate = (String, isBlank) => {
  const defaultDate = isBlank ? '' : moment();
  return String === null || String === '' ? defaultDate : moment(String);
}

// Date -> String 변환
export const changeGenderShortForDp = (gender) => {
  return gender === null || gender === '' ? '' : gender.substring(0, 1);
}

// Date -> String 변환
export const changeDateToString = (date, includeTime, timeOpt) => {
  let format = includeTime ? 'YYYY-MM-DD hh:mm:ss' : 'YYYY-MM-DD';
  format = timeOpt ? 'YYYY-MM-DD HH:mm:ss' : format; // 24시간표시
  return date === null || date === '' ? '' : moment(date).format(format);
}

// 오늘날짜
export const getToDateStringType = () => {
  let format = 'YYYY-MM-DD';
  return moment().format(format);
}

export const makePeriod = (_startDate, _endDate) => {
  const starDate = changeDateToStringForDp(_startDate);
  const endDate = changeDateToStringForDp(_endDate);
  return starDate + ' ~ ' + endDate;
}

// LocalDateTime(2024-02-29T03:30:00) -> String date / time 변환
export const makeLocalDateTimeToDateTime = (date, time) => {
  if (date === null || date === '') {
    return {
      date: moment().format('YYYY-MM-DD'),
      time: '',
    };
  } else {
    return {
      date: moment(date).format('YYYY-MM-DD'),
      time: time ? time : moment(date).format('HH:mm'),
    };
  }
}

/** group array : [{name:'사과',...},{name:'사과',...}]
 * 같은 value값으로 배열을 묶어준다.
 */
export const groupBy = (array, f) => {
  const groups = {};
  array.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};

export const makeUserAvatarImg = (userPhotoImg, isPhoto, gender) => {
  let val = gender === "MALE"
    ? require("@/assets/images/avatars/003-man-1.svg")
    : require("@/assets/images/avatars/002-woman.svg");
  if (isPhoto === "Y") {
    val = userPhotoImg;
  }
  return val;
}

// age 변환
export const changeDOBToAgeForDp = (DOB) => {
  return DOB === null || DOB === '' ? '' : moment().diff(DOB, 'years');
}

// 중복배열 처리
export const dupArrayCheck = (wantArray) => {
  const set = new Set(wantArray);
  return [...set];
};

// 회원분류 라벨처리
export const memberTypeLbl = type => {
  let result = '';
  switch (type) {
    case true:
      result = '회원';
      break;
    case false:
      result = '탈퇴회원';
      break;
  }
  return result;
};

// 예약가능일 종류
export const bookingTypeLbl = type => {
  let result = '';
  switch (type) {
    case 'be4w':
      result = '예약일 전4주';
      break;
    case 'be3w':
      result = '예약일 전3주';
      break;
    case 'be2w':
      result = '예약일 전2주';
      break;
    case 'be1w':
      result = '예약일 전1주';
      break;
    case 'be0w':
      result = '당일예약';
      break;
    case '0w':
      result = '갤린더';
      break;
  }
  return result;
};

// 회원 로그 타이틀
export const LogGbnLbl = type => {
  let result = '';
  switch (type) {
    case 'ATTENDANCE':
      result = '출석';
      break;
    case 'LEAVE':
      result = '탈퇴사유';
      break;
    case 'CHARM':
      result = '받은부적';
      break;
    case 'LOGIN':
      result = '로그인';
      break;
  }
  return result;
};

// 신고구분 라벨처리
export const declarationGbnTypeLbl = type => {
  let result = '';
  switch (type) {
    case 'dailyShare':
      result = '게시글';
      break;
    case 'comment':
      result = '뎃글';
      break;
  }
  return result;
};

// 게시판 라벨처리
export const bbsTypeLbl = type => {
  let result = '';
  switch (type) {
    case 'notice':
      result = '공지사항';
      break;
    case 'banner':
      result = '광고배너';
      break;
  }
  return result;
};

// 병원타입 라벨처리
export const hospitalTypeLbl = type => {
  let result = '';
  switch (type) {
    case 'severe':
      result = '중증병원';
      break;
    case 'plastic':
      result = '성형외과';
      break;
  }
  return result;
};

/** 입력값이 NULL인지 체크 */
export const C_isNull = asValue => {
  if (asValue === null || asValue === undefined || asValue.toString().replace(/ /g, '') === '') {
    return true;
  }

  return false;
};

export const patientMemo = (e) => {
  if (e.length > 0) return "?";
  return "..";
}

export const isPhotoChecker = (e) => {
  if (e === "" || e === null) return "N";
  return "Y";
}

export const signupPathLabel = (e) => {
  if (e === "" || e === null) return "admin";
  return e;
}

export const secondsToTime = (e, textType) => {
  if (e === 0) return "";
  let h = Math.floor(e / 3600).toString().padStart(2, '0'),
    m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
    s = Math.floor(e % 60).toString().padStart(2, '0');

  // return h + ':' + m + ':' + s;
  if (textType) {
    h = Number(h);
    m = Number(m);
    s = Number(s);
    let dp = `${s}Sec`;
    if (m > 0) {
      dp = `${m}Min ${s}Sec`;
    }
    if (h > 0) {
      dp = `${h}Hour ${m}Min ${s}Sec`;
    }
    return dp;
  }
  return `${h}:${m}:${s}`;
}

/** 날짜계산 : today 는 0 ~~ */
export const caldate = day => {
  const loadDt = new Date();
  const date = new Date(Number(loadDt) + day * 1000 * 60 * 60 * 24);
  return getDateValue(date);
};

export const getDateValue = date => {
  const year = date.getFullYear();
  const month = zero_plus(date.getMonth() + 1);
  const day = zero_plus(date.getDate());

  const hours = zero_plus(date.getHours());
  const minutes = zero_plus(date.getMinutes());
  const seconds = zero_plus(date.getSeconds());

  // Will display time in 10:30:23 format
  const formattedTime = String(year) + String(month) + String(day) + String(hours) + String(minutes) + String(seconds);
  return formattedTime;
};

//  2날짜사이 비교 (2019-01-01, 2019-01-05)
export const dateDiff = (date1, date2) => {
  // console.log(date1, date2)
  let diffDate1 = date1 instanceof Date ? date1 : new Date(date1);
  let diffDate2 = date2 instanceof Date ? date2 : new Date(date2);
  // console.log('test');

  diffDate1 = new Date(diffDate1.getFullYear(), diffDate1.getMonth() + 1, diffDate1.getDate());
  diffDate2 = new Date(diffDate2.getFullYear(), diffDate2.getMonth() + 1, diffDate2.getDate());

  // var diff = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
  let diff = diffDate2.getTime() - diffDate1.getTime();
  diff = Math.ceil(diff / (1000 * 3600 * 24));

  return diff;
};

// 2021-06-14 11:46:11  -> 원하는 형태로~
export const dateFormater = (date, format) => {
  if (date === null || date === undefined) {
    return '';
  }

  let result;
  date = date.split('-').join('');
  date = date.split(':').join('');
  date = date.split(' ').join('');
  const yy = date.substr(0, 4);
  const mm = date.substr(4, 2);
  const dd = date.substr(6, 2);
  const hh = date.substr(8, 2);
  const MM = date.substr(10, 2);
  const ss = date.substr(12, 2);
  switch (format) {
    case '':
      result = yy + mm + dd + hh + MM + ss;
      break;
    case '.':
      result = yy + '.' + mm + '.' + dd;
      break;
    case '-':
      result = yy + '-' + mm + '-' + dd;
      break;
    case '/':
      result = yy + '/' + mm + '/' + dd;
      break;
    case 'han':
      result = yy + '년 ' + mm + '월 ' + dd + '일';
      break;
    case 'han_hsi':
      result = yy + '년 ' + mm + '월 ' + dd + '일 ' + hh + ':' + MM + ':' + ss;
      break;
    case 'han_hs':
      result = hh + '시' + MM + '분'; // + ss;
      break;
    case 'hanhms':
      result = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + MM + ':' + ss;
      break;
    case 'space':
      result = yy + ' ' + mm + ' ' + dd;
      break;
    case 'hm':
      result = hh + ':' + MM;
      break;
  }
  return result;
};

/** 숫자검증 */
export const C_isNum = asValue => {
  if (C_isNull(asValue)) {
    return false;
  }

  for (let i = 0; i < asValue.length; i++) {
    if (asValue.charAt(i) < '0' || asValue.charAt(i) > '9') {
      return false;
    }
  }

  return true;
};

export const zero_plus = str => {
  let result;
  if (str.toString().length === 1) {
    result = '0' + str;
  } else {
    result = str;
  }
  return result;
};

export const xlsxDownload = (jsonData, sheetName, outputFileName) => {
  const now = moment().format("YYYYMMDDhhmmss");
  // console.log(now)
  const excelData = XLSX.utils.json_to_sheet(jsonData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, excelData, sheetName);
  XLSX.writeFile(workBook, `${outputFileName}_${now}.xlsx`);
};

/** 1,000 */
export const commaNum = num => {
  if (num == null) {
    return '';
  }
  const str = num.toString();
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

/** 콤마 지우기
 * toLocaleString 콤마 지우기
 */
export const Uncomma = str => {
  str = String(str);
  return str.replace(/[^\d]+/g, '');
};

/** 사업자 / 법인 번호 자르기*/
export const bizNumber = (str, type) => {
  if (str === '' || null) return;
  let d = {};
  // 법인번호
  if (type === 'CORP_NO') {
    d.CORP_NO1 = str.substr(0, 6);
    d.CORP_NO2 = str.substr(6, 13);
  }
  if (type === 'BIZM_REG_NO') {
    d.CORP_NO1 = str.substr(0, 3);
    d.CORP_NO2 = str.substr(3, 2);
    d.CORP_NO3 = str.substr(5, 11);
  }
  return d;
};

/** 폽번호 자르기*/
export const phoneNumberDivide = (str, type) => {
  if (str === '' || null) return;
  let d = {};
  // 휴대폰번호 010-1234-0984
  if (type === 'CELL-' || type === '-') {
    const cell = str.split('-');
    d.NO1 = cell[0] === 'undefined' ? '' : cell[0];
    d.NO2 = cell[1] === 'undefined' ? '' : cell[1];
    d.NO3 = cell[2] === 'undefined' ? '' : cell[2];
  }
  // if (type === 'BIZM_REG_NO') {
  //     d.CORP_NO1 = str.substr(0, 3)
  //     d.CORP_NO2 = str.substr(3, 2)
  //     d.CORP_NO3 = str.substr(5, 11)
  // }
  return d;
};

export const userTypeLbl = type => {
  let result = '';
  switch (type) {
    case '5':
      result = 'Admin';
      break;
    case '2':
      result = 'Master';
      break;
    case '3':
      result = 'Manager';
      break;
  }
  return result;
};

export const TextDiffSecLbl = Diff => {
  let result = Diff + 's';
  if (Diff === '' || Diff === 0) {
    result = '';
  }
  return result;
};

// 특수 문자 체크
export const checkSpecial = str => {
  const regExp = /[!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 한글 체크 
export const checkKor = str => {
  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 숫자 체크 
export const checkNum = str => {
  const regExp = /[0-9]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 영문(영어) 체크 
export const checkEng = str => {
  const regExp = /[a-zA-Z]/g;
  // 영어
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 영문+숫자만 입력 체크 
export const checkEngNum = str => {
  const regExp = /[a-zA-Z0-9]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 공백(스페이스 바) 체크 
export const checkSpace = str => {
  if (str.search(/\s/) !== -1) {
    return true;
    // 스페이스가 있는 경우
  } else {
    return false;
    // 스페이스 없는 경우
  }
};

// "https://icaregc.s3.ap-northeast-2.amazonaws.com/CMS/work/work-image_20211008164324.jpg" -> 파일명 추출
export const findFileName = fullURL => {
  if (fullURL === null || fullURL === '') return false;
  const strArr = fullURL.split('/');
  // console.log('', strArr[strArr.length - 1]);
  return strArr[strArr.length - 1];
};

// 이미지파일인지 확인~
export const checkImageFile = filename => {
  if (filename === '' || filename === null) return;

  // Image 파일인 경우 미리보기 UI 제공
  const _lastDot = filename.lastIndexOf('.');
  const _fileExt = filename.substring(_lastDot, filename.length).toLowerCase();
  // console.log(_fileExt);
  let imgPreview = false;
  if (_fileExt === '.png' || _fileExt === '.jpg' || _fileExt === '.jpeg' || _fileExt === '.gif') {
    imgPreview = true;
  }
  return imgPreview;
};

// 파일존재여부 확인
export const isFileLabel = fileName => {
  if (fileName === '' || fileName === null) {
    return 'N';
  } else {
    return 'Y'
  }
};

// 공통 메세지 처리
export const commonMessage = type => {
  let result = '';
  switch (type) {
    case 'E':
      result = 'An error occurred';
      break;
    case 'S':
      result = 'Saved';
      break;
    case 'U':
      result = 'It is changed';
      break;
    case 'D':
      result = 'Has been deleted';
      break;
  }
  return result;
};

// 그래프에 리스크 기준을 표시하기 위한 메쏘드
export const makeRiskData = risk => {
  const BpLIdx = risk.map(x => x.kind).indexOf("Diastolic"); // 혈압낮은기준
  const BpHIdx = risk.map(x => x.kind).indexOf("Systolic"); // 혈압높은기준
  const BgBIdx = risk.map(x => x.kind).indexOf("PreMeal"); // 당뇨식전기준
  const BgAIdx = risk.map(x => x.kind).indexOf("PostMeal"); // 당뇨식후기준
  // console.log("", BpHIdx, BpLIdx, BgBIdx, BgAIdx);
  const BPRisk = {};
  const BGRisk = {};
  // 초기값
  BPRisk.L = [0, 0];
  BPRisk.H = [0, 0];
  if (BpLIdx > -1) {
    const item = risk[BpLIdx];
    BPRisk.L = [item.Low, item.high];
  }
  if (BpHIdx > -1) {
    const item = risk[BpHIdx];
    BPRisk.H = [item.Low, item.high];
  }
  // 초기값
  BGRisk.B = [0, 0];
  BGRisk.A = [0, 0];
  if (BgBIdx > -1) {
    const item = risk[BgBIdx];
    BGRisk.B = [item.Low, item.high];
  }
  if (BgAIdx > -1) {
    const item = risk[BgAIdx];
    BGRisk.A = [item.Low, item.high];
  }
  // console.log("", BPRisk, BGRisk);
  let riskData = [];
  riskData.BP = BPRisk;
  riskData.BG = BGRisk;
  return riskData;
};

export const imageResize = (image, maxSize, zipRatio) => {
  let canvas = document.createElement("canvas");
  let max_size = maxSize;
  let width = image.width;
  let height = image.height;

  if (width > height) {
    // 가로가 길 경우
    if (width > max_size) {
      height *= max_size / width;
      width = max_size;
    }
  } else {
    // 세로가 길 경우
    if (height > max_size) {
      width *= max_size / height;
      height = max_size;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d").drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL("image/jpeg", zipRatio);
  console.log("", width, height);

  // 미리보기 위해서 마크업 추가.
  return dataUrl;
}

export const dataURLToBlob = (dataURL) => {
  const BASE64_MARKER = ";base64,";

  // base64로 인코딩 되어있지 않을 경우
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1];
    const raw = parts[1];
    return new Blob([raw], {
      type: contentType,
    });
  }
  // base64로 인코딩 된 이진데이터일 경우
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  // atob()는 Base64를 디코딩하는 메서드
  const rawLength = raw.length;
  // 부호 없는 1byte 정수 배열을 생성
  const uInt8Array = new Uint8Array(rawLength); // 길이만 지정된 배열
  let i = 0;
  while (i < rawLength) {
    uInt8Array[i] = raw.charCodeAt(i);
    i++;
  }
  return new Blob([uInt8Array], {
    type: contentType,
  });
}